export const scheduleProducts = ({ products, capacity }) => {
        
    let carsSchedule = [];
    let allProducts = products.filter(product => product.count <= capacity);
    let i = 31;

    while (allProducts.length > 0 && i > 0) { 
      i--;
      let currentCarCapacity = parseInt(capacity);
      const currentCarContents = findLargestSum(allProducts, currentCarCapacity);

      const productsSum = currentCarContents.reduce((acc, current) => current.count + acc, 0);
      currentCarCapacity -= currentCarCapacity - productsSum;
      
      carsSchedule.push({ currentCarContents, productsSum, freeSpace: capacity - productsSum })   

      allProducts = allProducts.filter(function(newProduct){
          return !currentCarContents.find(function(currentCarItem){
              return newProduct.id === currentCarItem.id;
          });
      });

    }
    return carsSchedule;
}

function findLargestSum(products, capacity) {
  let largestSum = 0;
  let result = [];

  function dfs(index, sum, selected) {
    if (sum <= capacity && sum > largestSum) {
      largestSum = sum;
      result = selected.slice();
    }
    if (sum >= capacity || index === products.length) {
      return;
    }
    dfs(index + 1, sum + products[index].count, [...selected, products[index]]);
    dfs(index + 1, sum, selected);
  }

  dfs(0, 0, []);

  return result;
}