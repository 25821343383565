import logo from './logo.svg';
import './App.css';
import ProductForm from './components/ProductForm';
import Result from './components/Result';

function App() {
  return (

    // <div className="App">
      //  <header className="App-header">
          <ProductForm />
      // </header>
    //  </div>
  );
}

export default App;
