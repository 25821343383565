import React from 'react';
import './Result.css'

const Result = ({ result }) => {
  return (
    <table>
      <thead>
        <tr>
          <th>Przejazd</th>
          <th>Produkty</th>
          <th>Suma</th>
          <th>Wolne miejsce</th>
        </tr>
      </thead>
      <tbody>{
          result.map((item, index) => (
          <tr key={index + 1}>
            <td>{index + 1}</td>
            <td>{item.currentCarContents.map(item => item.name + ', ')}</td>
            <td>{item.productsSum}</td>
            <td>{item.freeSpace}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default Result;