import React, { useState } from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import './ProductForm.css';
import { scheduleProducts } from '../helpers/scheduleProducts'
import Result from './Result';

const ProductForm = () => {
 
  const [products, setProducts] = useState([]);
  const [capacity, setCapacity] = useState(1);
  const [result, setResult] = useState([]);

  const handleProductNameChange = (event, id) => {
    const updatedProducts = [...products];
    const product = updatedProducts.find(product => product.id === id);
    product.name = event.target.value;
    setProducts(updatedProducts);
  };

  const handleProductCountChange = (event, id) => {
    const count = parseInt(event.target.value);
    const updatedProducts = [...products];
    const product = updatedProducts.find(product => product.id === id);
    product.count = count;
    product.warning = count > capacity ? true : false;
    setProducts(updatedProducts);
  };

  const handleCapacityChange = (event) => {
    const providedCapacity = parseInt(event.target.value);
    setCapacity(providedCapacity);
    const updatedProducts = [...products].map(product => {
      product.warning = product.count > providedCapacity;
      return product;
    });
    setProducts(updatedProducts);
  };

  const handleDeleteProduct = (id) => {
    const updatedProducts = products.filter(product => product.id !== id);
    setProducts(updatedProducts);
  };

  const handleAddProduct = () => {
    if (products.length > 30) {
      return;
    }
    const random = Math.ceil(Math.random() * 100000);
    const newProducts = [...products, { name: '', count: 1, id: random, warning: false }];
    setProducts(newProducts);
  };

  const handleScheduleProducts = (event) => {
    event.preventDefault();

    const carContents = scheduleProducts({ products, capacity })
    setResult(carContents)
  };

  const handleClearProducts = () => {
    setProducts([])
    setResult([])
  }


  return (
    <div className="main-wrapper"> 
        <form className="main-form" onSubmit={handleScheduleProducts}>
            <button type="button" className="add-product" onClick={handleAddProduct}>+ Dodaj produkt</button>
            <div className="product-inputs">
                {products.map((product) => (
                <div className={`product-input ${product.warning ? ' warning':''}`} key={product.id}>
                  <div className="product-input-name">
                      <input
                          type="text"
                          placeholder='nazwa produktu'
                          name="name"
                          value={product.name}
                          onChange={(event) => handleProductNameChange(event, product.id)}
                      />
                    </div>
                
                    <div className="product-input-count">
                      <input
                          placeholder='0'
                          inputMode="numeric"
                          type="number"
                          min="0"
                          name="count"
                          value={product.count}
                          onChange={(event) => handleProductCountChange(event, product.id)}
                      />
                    </div>
                      <DeleteIcon 
                        className='product-icon-delete'
                        color={'error'} 
                        fontSize={'large'}
                        onClick={(event) => handleDeleteProduct(product.id)}
                      />
                 

                </div>
                ))}
            </div>

            <div className="product-input-car">
                <label>
                    Pojemność pojazdu:
                </label>
                <input type="number" min="0" inputMode="numeric" value={capacity} onChange={handleCapacityChange} />
            </div>
            
            <button className="product-submit" type="submit">Zaplanuj</button>
            {
              products.length > 0  &&
              <button className="product-clear" type="submit" onClick={handleClearProducts}>Wyczyść</button>
            }
            
        </form>
        <Result result={result}/>
    </div>
    
  );
};

export default ProductForm;
